import React, { useEffect } from "react"

export default function TestPage() {

  // console.log(data)

  return (
    <div>
      <p>Hello test Page</p>
    </div>
  )
}

// export async function getData() {
//   const data = await getCategories()
//   return data
// }
